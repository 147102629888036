:root {
    --universal-border-radius: 0.425rem;
}

html, body, #main {
    height: 100%;
}

#main {
    max-width: 30rem;
    background-color: #a3aab7;
    padding: var(--universal-padding);
    border-radius: var(--universal-border-radius);
}

input {
    width: 100%;
}

.check-button {
    margin-bottom: 0.4rem;
}

.check-button span {
    overflow: visible;
    text-transform: none;
    background: var(--button-back-color);
    color: var(--button-fore-color);
    border-radius: var(--universal-border-radius);
    border: 0.0625rem solid var(--button-border-color);
    padding: calc(0.4 * var(--universal-padding)) calc(0.8 * var(--universal-padding));
    text-decoration: none;
    cursor: pointer;
    transition: background 0.3s;
}

#play-button, #play-button span {
    display: inline-block;
    border-radius: calc(1.8 * (var(--universal-border-radius)));
    padding-top: 0;
    padding-bottom: calc(0.8 * var(--universal-padding));
    padding-left: calc(4.5 * var(--universal-padding));
    padding-right: calc(4.0 * var(--universal-padding));
    font-size: 3em;
}

#play-button:hover, #play-button:focus {
    background: var(--button-hover-back-color);
    border-color: var(--button-hover-border-color);
}

#play-button, #play-button label {
    padding: 0;
}

#play-button {
    margin: var(--universal-margin);
}

.check-button input:checked + span {
    background: var(--button-fore-color);
    color: var(--button-back-color);
}

#notes, #subdivision {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.row {
    margin-bottom: var(--universal-margin);
}

label {
    padding-right: 0;
}

output {
    padding: 0;
    font-weight: bold;
    font-size: 1.2em;
}

